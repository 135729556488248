var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { align: "center" } }, [
    _c("br"),
    _vm._m(0),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-md-4 col-12 q-pr-md", attrs: { align: "left" } },
        [
          _c(
            "q-list",
            { attrs: { separator: "" } },
            [
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Area:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.area)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Prodotto:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.id_prodotto)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Numero preventivo:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.guid_preventivo)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Descrizione preventivo:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.nominativo)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Stato della quotazione:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.stato)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Unità Operativa:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.unita_operativa)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Operatore:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.operatore)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-6 col-12 q-pr-md", attrs: { align: "left" } },
        [
          _c("h6", { staticClass: "q-mt-sm" }, [
            _vm._v("Inserisci il motivo del rifiuto"),
          ]),
          _c("q-input", {
            attrs: { outlined: "", square: "", filled: "", type: "textarea" },
            model: {
              value: _vm.annotazione,
              callback: function ($$v) {
                _vm.annotazione = $$v
              },
              expression: "annotazione",
            },
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("hr"),
    _c("div", { staticClass: "row", attrs: { align: "center" } }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "INDIETRO",
              color: "blue-grey",
              icon: "undo",
              size: "md",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onIndietroClicked.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "REGISTRA RIFIUTO",
              color: "blue-grey",
              icon: "mdi-content-save-outline",
              size: "md",
              icon_align: "right",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onRegistraRifiuto.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [_vm._v("Rifiuto quotazione")]),
      _c("br"),
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }